var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'flex px-3 py-2 justify-between items-center cursor-pointer rounded focus:outline-none',
    { border: !_vm.noBorder },
    { 'hover:bg-gray-light': !_vm.noBackground },
    { 'border-gray': !_vm.noBorder },
    { 'bg-gray-light': _vm.selected },
    { 'bg-white': !_vm.selected },
    { 'bg-transparent': _vm.noBackground },
  ],attrs:{"data-test":_vm.dataTest},on:{"click":event => _vm.$emit('click', event)}},[_c('div',{staticClass:"flex-grow-0 flex-shrink-0 w-6",class:{
      'text-gray-black': false,
      'text-black': true,
      'ml-3': _vm.direction === 'rtl',
    }},[(_vm.prefix)?_c('span',{staticClass:"font-bold"},[_vm._v(_vm._s(_vm.prefix))]):(_vm.prefixFlag)?_c('flag',{staticClass:"rounded-sm",attrs:{"country":_vm.prefixFlag}}):_vm._t("prefix")],2),_vm._v(" "),(_vm.countryCode)?_c('div',{staticClass:"flex-grow-0 flex-shrink-0 w-6 mx-3",attrs:{"dir":"ltr"}},[_c('span',{staticClass:"font-bold"},[_vm._v(_vm._s('+' + _vm.countryCode))])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"capitalize ps-4 flex-grow",class:{ 'text-gray-black': false, 'text-black': true }},[(_vm.content)?_c('span',[_vm._v(" "+_vm._s(_vm.content))]):_vm._t("default"),_vm._v(" "),(_vm.suffix)?_c('span',{class:{
        'font-bold': _vm.boldSuffix,
      }},[_vm._v("\n      "+_vm._s(_vm.suffix)+"\n    ")]):_vm._e()],2),_vm._v(" "),_c('div',{staticClass:"flex-grow-0"},[(_vm.suffixIcon)?_c('icon',_vm._b({staticClass:"block responsive-icon"},'icon',_vm.suffixIcon,false)):_vm._t("suffix")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }