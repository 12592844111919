<template>
  <div
    :class="[
      'flex px-3 py-2 justify-between items-center cursor-pointer rounded focus:outline-none',
      { border: !noBorder },
      { 'hover:bg-gray-light': !noBackground },
      { 'border-gray': !noBorder },
      { 'bg-gray-light': selected },
      { 'bg-white': !selected },
      { 'bg-transparent': noBackground },
    ]"
    :data-test="dataTest"
    @click="event => $emit('click', event)"
  >
    <div
      class="flex-grow-0 flex-shrink-0 w-6"
      :class="{
        'text-gray-black': false,
        'text-black': true,
        'ml-3': direction === 'rtl',
      }"
    >
      <span v-if="prefix" class="font-bold">{{ prefix }}</span>
      <flag v-else-if="prefixFlag" :country="prefixFlag" class="rounded-sm" />
      <slot v-else name="prefix" />
    </div>
    <div
      v-if="countryCode"
      dir="ltr"
      class="flex-grow-0 flex-shrink-0 w-6 mx-3"
    >
      <span class="font-bold">{{ '+' + countryCode }}</span>
    </div>

    <div
      class="capitalize ps-4 flex-grow"
      :class="{ 'text-gray-black': false, 'text-black': true }"
    >
      <span v-if="content"> {{ content }}</span>
      <slot v-else />
      <span
        v-if="suffix"
        :class="{
          'font-bold': boldSuffix,
        }"
      >
        {{ suffix }}
      </span>
    </div>
    <div class="flex-grow-0">
      <icon
        v-if="suffixIcon"
        v-bind="suffixIcon"
        class="block responsive-icon"
      />
      <slot v-else name="suffix" />
    </div>
  </div>
</template>
<script>
import { Icon, Flag } from '~/components/atoms'

export default {
  components: {
    Icon,
    Flag,
  },
  props: {
    content: {
      type: String,
      default: null,
    },
    noBorder: {
      type: Boolean,
      default: false,
    },
    noBackground: {
      type: Boolean,
      default: false,
    },
    prefix: {
      type: String,
      default: null,
    },
    prefixFlag: {
      type: String,
      default: null,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    suffix: {
      type: String,
      default: null,
    },
    boldSuffix: {
      type: Boolean,
      default: false,
    },
    suffixIcon: {
      type: Object,
      default: null,
    },
    countryCode: {
      type: String,
      default: null,
    },
    dataTest: {
      type: String,
      default: null,
    },
    direction: {
      type: String,
      default: 'ltr',
    },
  },
}
</script>
